import React, { useState } from 'react'

import { Query, Mutation } from '@apollo/client/react/components'
import { withApollo } from '@apollo/client/react/hoc'
import { Button, Collapse } from 'antd'
import findIndex from 'lodash/findIndex'
import _fp from 'lodash/fp'
import get from 'lodash/get'
import keys from 'lodash/keys'
import memoize from 'lodash/memoize'
import remove from 'lodash/remove'
import set from 'lodash/set'

import useModalManagement from '../../../../../../../hooks/useModalManagement'
import errorService from '../../../../../../../utils/analytics/error'
import { pickWithInitialState, extractIdsFromList } from '../../../../../../../utils/data/utils'
import feedback from '../../../../../../../utils/feedback'
import { client } from '../../../../../../../utils/graphql/configuration'
import ManageTeamFunctionalityContainer from '../ManageTeamFunctionalityContainer'

import CreateTeamForm from './components/CreateTeamForm'
import EditTeamForm from './components/EditTeamForm'
import TeamInfo from './components/TeamInfo'
import * as tags from './tags'

const { Panel } = Collapse

/**
 * Maps documents and reports into correct GraphQL input structure
 */
const mapDocumentsAndReportsIntoGraphQLStructure = (values = []) => {
  return values.map(v => {
    const { id, permissions } = v
    return {
      id,
      permissions,
    }
  })
}

/**
 * Maps documents and reports into necessary form field structure
 * @param {String} key The name of the property in the object (document | report)
 * @param {*} formFieldGroup The name of the form field (documents | reports)
 */
const mapDocumentsAndReportsIntoFormStructure = (key = '', formFieldGroup = '') =>
  _fp.reduce(
    (acc, current) =>
      set(acc, `${formFieldGroup}.${get(current, `${key}.id`)}`, get(current, 'permissions')),
    {},
  )

/**
 * Creates the team-based mutation input
 * @param {Object} formData
 */
const getTeamMutationInput = formData => {
  const { documents, members, name, reports } = pickWithInitialState(formData, [
    ['documents', []],
    ['members', []],
    ['name', null],
    ['reports', []],
  ])

  return {
    documentPermissions: mapDocumentsAndReportsIntoGraphQLStructure(documents),
    members: extractIdsFromList(members),
    name,
    reportPermissions: mapDocumentsAndReportsIntoGraphQLStructure(reports),
  }
}

/**
 * Maps an existing team to the necessary edit form initial values
 * @param {Object} team
 */
const mapInitialValues = (team = {}) => {
  const { documentPermissions, reportPermissions, ...rest } = pickWithInitialState(team, [
    ['id', ''],
    ['documentPermissions', []],
    ['members', []],
    ['name', ''],
    ['reportPermissions', []],
  ])

  return {
    ...rest,
    ...mapDocumentsAndReportsIntoFormStructure('document', 'documents')(documentPermissions),
    ...mapDocumentsAndReportsIntoFormStructure('report', 'reports')(reportPermissions),
  }
}

const ManageTeams = props => {
  const [activeTeam, setActiveTeam] = useState(null)
  const createTeamModalProps = useModalManagement(false)
  const editTeamModalProps = useModalManagement(false)

  /**
   * Creates a new team
   */
  const onCreateTeamSubmit = (createUniversityClientTeam, id) => async formData => {
    const currentUpdatedTeam = {
      id: get(formData, 'id'),
      members: get(formData, 'members'),
      name: get(formData, 'name'),
    }

    if (formData.reports) {
      const reports = get(formData, 'reports', {})
      const finalReports = keys(reports).map(id => {
        return {
          id,
          permissions: get(reports, id),
        }
      })

      currentUpdatedTeam['reports'] = finalReports
    }

    if (formData.documents) {
      const documents = get(formData, 'documents', {})

      const finalDocuments = keys(documents).map(id => {
        return {
          id,
          permissions: get(documents, id),
        }
      })

      currentUpdatedTeam['documents'] = finalDocuments
    }

    try {
      await createUniversityClientTeam({
        variables: {
          data: {
            ...getTeamMutationInput(currentUpdatedTeam),
            universityClient: id,
          },
        },
      })
    } catch (error) {
      errorService.report(error, 'createUniversityClientTeam')
      feedback.error({ content: error.message, title: 'Error creating team' })
    } finally {
      createTeamModalProps.closeModal()
    }
  }

  /**
   * Modifies a team
   */
  const onEditTeamSubmit = modifyUniversityClientTeam => async formData => {
    try {
      await modifyUniversityClientTeam({
        update: (cache, { data: { modifyUniversityClientTeam } }) => {
          // Update teams query cache
          const teamsData = cache.readQuery({
            query: tags.univeristyClientTeamsQuery,
          })

          if (teamsData) {
            const teams = get(teamsData, 'universityClient.teams', [])
            const teamIndex = teams.findIndex(t => t.id === modifyUniversityClientTeam.id)

            if (teamIndex > -1) {
              const updatedTeams = [
                ...teams.slice(0, teamIndex),
                modifyUniversityClientTeam,
                ...teams.slice(teamIndex + 1),
              ]

              cache.writeQuery({
                data: {
                  universityClient: {
                    ...teamsData.universityClient,
                    teams: updatedTeams,
                  },
                },
                query: tags.univeristyClientTeamsQuery,
              })
            }
          }
        },
        variables: {
          data: {
            ...getTeamMutationInput(formData),
            id: get(formData, 'id'),
          },
        },
      })
      feedback.success({ content: 'Team updated successfully' })
    } catch (error) {
      errorService.report(error, 'modifyUniversityClientTeam')
      feedback.error({ content: error.message, title: 'Error updating team' })
    } finally {
      editTeamModalProps.closeModal()
    }
  }

  const updateActiveTeam = () => {
    const activeTeamId = get(activeTeam, 'id')
    const team = props.client.readFragment({
      fragment: TeamInfo.fragments,
      id: `UniversityClientTeam:${activeTeamId}`,
    })
    setActiveTeam(team)
  }

  const onReassignTeamSubmit = refetchCallback => async (member, currentTeamId, newTeamId) => {
    try {
      const response = await client.mutate({
        mutation: tags.reassignTeamMemberMutation,
        variables: {
          data: {
            currentTeamId,
            member,
            newTeamId,
          },
        },
      })
      const result = get(response, 'data.reassignTeamMember')
      if (result) {
        await refetchCallback()
        updateActiveTeam()
        feedback.success({ content: 'Member reassigned successfully' })
      }
    } catch (error) {
      errorService.report(error, 'reassignMemberMutation')
      feedback.error({
        title: 'Error reassigning team member..Please try again',
      })
    }
  }

  /**
   * delete selected team
   * @param {String} id
   */
  const onDeleteTeam = async id => {
    try {
      const response = await client.mutate({
        mutation: tags.deleteTeamMutation,
        variables: {
          teamId: id,
        },
      })
      const result = get(response, 'data.deleteUniversityClientTeam')
      if (result) {
        const cachedData = client.readQuery({
          query: tags.univeristyClientTeamsQuery,
        })
        const cachedTeams = [...get(cachedData, 'universityClient.teams')]
        remove(cachedTeams, team => {
          return team.id === id
        })
        client.writeQuery({
          data: cachedData,
          query: tags.univeristyClientTeamsQuery,
        })
        feedback.success({ content: 'Team deleted successfully' })
      }
    } catch (error) {
      errorService.report(error, 'deleteTeamMutation')
      feedback.error({
        title: 'Error deleting team..Please try again',
      })
    }
  }

  const memoizedOnCreateTeamSubmit = memoize(onCreateTeamSubmit)

  return (
    <ManageTeamFunctionalityContainer
      extra={
        <Button type="primary" onClick={createTeamModalProps.openModal}>
          Create Team
        </Button>
      }
      title="Teams"
    >
      <Query query={tags.univeristyClientTeamsQuery} fetchPolicy="cache-and-network">
        {({ data, refetch }) => {
          const universityClient = get(data, 'universityClient')
          const { admins, id, teams, documents, reports, members } = pickWithInitialState(
            universityClient,
            [
              ['admins', []],
              ['id', null],
              ['teams', []],
              ['documents', []],
              ['reports', []],
              ['members', []],
            ],
          )
          return (
            <div>
              <Collapse
                expandIconPosition="right"
                style={{ background: '#ffffff', border: 'none' }}
                accordion
              >
                {teams.map(currentTeam => (
                  <Panel
                    className="team-management-panel"
                    key={currentTeam.id}
                    header={
                      <TeamInfo
                        admins={admins}
                        allTeams={teams}
                        documents={documents}
                        key={currentTeam.id}
                        team={currentTeam}
                        onEditTeamSubmit={onEditTeamSubmit}
                        onDelete={onDeleteTeam}
                        onReassignTeamSubmit={onReassignTeamSubmit}
                        refetchTeams={refetch}
                        reports={reports}
                        tags={tags}
                      />
                    }
                  >
                    <Mutation
                      mutation={tags.modifyUniversityClientTeamMutation}
                      update={(store, { data: { modifyUniversityClientTeam } }) => {
                        const cachedData = store.readQuery({
                          query: tags.univeristyClientTeamsQuery,
                        })

                        const indexToModify = findIndex(
                          get(cachedData, 'universityClient.teams', []),
                          modifyUniversityClientTeam,
                        )
                        cachedData.universityClient.teams.splice(
                          indexToModify,
                          1,
                          modifyUniversityClientTeam,
                        )
                        store.writeQuery({
                          data: cachedData,
                          query: tags.univeristyClientTeamsQuery,
                        })
                        refetch()
                      }}
                    >
                      {modifyUniversityClientTeam => (
                        <EditTeamForm
                          admins={admins}
                          documents={documents}
                          reports={reports}
                          clientMembers={members}
                          initialValues={mapInitialValues(currentTeam)}
                          team={currentTeam}
                          onSubmit={onEditTeamSubmit(modifyUniversityClientTeam)}
                          allTeams={teams}
                          onReassignTeamSubmit={onReassignTeamSubmit(refetch)}
                        />
                      )}
                    </Mutation>
                  </Panel>
                ))}
              </Collapse>
              <Mutation
                refetchQueries={[{ query: tags.univeristyClientTeamsQuery }]}
                mutation={tags.createUniversityClientTeamMutation}
                update={() => {
                  feedback.success({ content: 'Team created successfully' })
                }}
              >
                {createUniversityClientTeam => (
                  <CreateTeamForm
                    documents={documents}
                    reports={reports}
                    members={members}
                    modalProps={{
                      ...createTeamModalProps,
                      title: 'Create New Team',
                    }}
                    onSubmit={memoizedOnCreateTeamSubmit(createUniversityClientTeam, id)}
                  />
                )}
              </Mutation>
            </div>
          )
        }}
      </Query>
    </ManageTeamFunctionalityContainer>
  )
}

ManageTeams.propTypes = {}

export default withApollo(ManageTeams)
