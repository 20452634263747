const BUILDING_ICON_URL = 'https://steppingblocks-shared-assets.s3.amazonaws.com/building-light.png'

const SHARED_S3_URL = 'https://steppingblocks-shared-assets.s3.amazonaws.com/'

const REPORTS_PERMISSIONS_LABELS = {
  DOWNLOAD: 'Read & Download',
  DOWNLOAD_FULL: 'Read & Full Download',
  DOWNLOAD_LIMITED: 'Read & Limited Download',
  READ: 'Read',
  VIEWCONTACT: 'Read & View Contact',
}

const REPORT_IMAGES = [
  // eslint-disable-next-line import/order
  require('../../assets/graduate-outcomes-thumbnail.png'),
  require('../../assets/graduate-explorer-thumbnail.png'),
  // eslint-disable-next-line import/order
  require('../../assets/undraw/reports_003.svg'),
]

const REPORT_DESCRIPTIONS = {
  'Basic Graduate Outcomes': 'Learn about employment and salary trends for your graduates.',
  'College Outcomes': 'High-Level insights for University Leadership',
  'College to Career Insights':
    'Create your own custom aggregations to download for marketing materials and reporting',
  'Graduate Explorer':
    'Find specific alumni using demographic, education, and career related filters.',
  'Graduate Outcomes':
    'Find actionable insights on alumni locations, employment, salaries, and skills',
  'Graduate Outcomes Campus': 'High-Level insights for University Leadership',
  'Graduation To Employment': "Explore your graduate's first destination of employment (Beta)",
  'Search Match': 'Find Alumni records matching Steppingblocks records',
}

const REPORT_USEDBYS = {
  'College to Career Insights': 'Institutional Research, Deans, Admissions, Marketing',
  'Graduate Explorer': 'Advancement, Alumni Relations, Employer Relations, Institutional Research',
  'Graduate Outcomes':
    'Provosts, Deans, Career Services, Employer Relations, Institutional Research',
}

const REPORT_TYPES = {
  react: 'react',
  tableau: 'tableau',
}

/**
 * Gets the image source based on modulo of the index
 * @param {Array} images
 * @param {Int} index
 */
const GET_RANDOM_REPORT_IMAGE = (images = [], index) => {
  const mod = index % images.length
  return images[mod]
}

const DOCUMENT_IMAGES = [
  require('../../assets/undraw/documents_001.svg'),
  require('../../assets/undraw/documents_002.svg'),
  require('../../assets/undraw/documents_003.svg'),
]

const TALENT_SEARCH_FILTER_NAMES = {
  education: 'education',
}

const RANGE_FILTERS = [
  'ages',
  'incomes',
  'yearsExperience',
  'estSalary',
  'years_of_experience',
  'salary',
]

const REQUISITION_DETAIL_MODES = {
  create: 'create',
  edit: 'edit',
}

const TE_CANDIDATE_SORT_OPTIONS = [
  {
    display: 'Best Overall',
    value: 'sort',
  },
  {
    display: 'Salary Desc',
    value: 'salary_desc',
  },
  {
    display: 'Salary Asc',
    value: 'salary_asc',
  },
  {
    display: 'Company Size Desc',
    value: 'co_size_desc',
  },
  {
    display: 'Company Size Asc',
    value: 'co_size_asc',
  },
  {
    display: 'Tenure Desc',
    value: 'tenure_desc',
  },
  {
    display: 'Tenure Asc',
    value: 'tenure_asc',
  },
]

const TUTORIALS = {
  graduateExplorer: {
    description: 'Find specific alumni using demographic, education, and career related filters.',
    title: 'Graduate Explorer',
    videoSrc: 'https://www.youtube.com/embed/GamhGz7tOew?autoplay=1',
  },
  graduateOutcomes: {
    description: 'Find actionable insights on alumni locations, employment, salaries, and skills.',
    title: 'Graduate Outcomes',
    videoSrc: 'https://www.youtube.com/embed/q8sk0BubHgQ?autoplay=1',
  },
}

const INCLUDE_UNIVERSITY_EMPLOYEES = 'include_university_employees'

const IMAGES = {
  blankMap: 'Blank_US_Map.png',
  building: 'building-light.png',
  filterThumb: 'data.steppingblocks/FilterThumb.png',
  nsfLogo: 'NSF_Logo.png',
  teamsThumb: 'TeamsThumb.png',
}

const CLEARBIT_URL = 'https://logo.clearbit.com/'

const TAXONOMIES = {
  GENERAL: 'general',
  SCHOOL: 'school',
}

const REPORT_NAMES = {
  GraduateExplorer: 'Graduate Explorer',
  GraduateOutcomes: 'Graduate Outcomes',
}

module.exports = {
  BUILDING_ICON_URL,
  CLEARBIT_URL,
  DOCUMENT_IMAGES,
  GET_RANDOM_REPORT_IMAGE,
  IMAGES,
  INCLUDE_UNIVERSITY_EMPLOYEES,
  RANGE_FILTERS,
  REPORTS_PERMISSIONS_LABELS,
  REPORT_DESCRIPTIONS,
  REPORT_IMAGES,
  REPORT_NAMES,
  REPORT_TYPES,
  REPORT_USEDBYS,
  REQUISITION_DETAIL_MODES,
  SHARED_S3_URL,
  TALENT_SEARCH_FILTER_NAMES,
  TAXONOMIES,
  TE_CANDIDATE_SORT_OPTIONS,
  TUTORIALS,
}
