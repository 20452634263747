import React from 'react'

export const TriangleExclamation = ({ size = 16, style }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <g id="triangle-exclamation">
      <path
        id="Vector"
        d="M7.81206 3.75213C7.85164 3.68534 7.92339 3.64575 8.00008 3.64575C8.07677 3.64575 8.14852 3.68534 8.1881 3.75213L13.094 11.8098C13.1286 11.8667 13.1459 11.931 13.1459 11.9954C13.1459 12.1933 12.9851 12.3541 12.7872 12.3541H3.21297C3.01506 12.3541 2.85425 12.1933 2.85425 11.9954C2.85425 11.9286 2.87157 11.8642 2.9062 11.8098L7.81206 3.75213ZM6.79774 3.13364L1.89188 11.1913C1.74344 11.4338 1.66675 11.7109 1.66675 11.9954C1.66675 12.8489 2.35946 13.5416 3.21297 13.5416H12.7872C13.6407 13.5416 14.3334 12.8489 14.3334 11.9954C14.3334 11.7109 14.2542 11.4338 14.1083 11.1913L9.20243 3.13364C8.94761 2.71554 8.4924 2.45825 8.00008 2.45825C7.50776 2.45825 7.05256 2.71554 6.79774 3.13364ZM8.79175 10.7708C8.79175 10.5608 8.70834 10.3594 8.55987 10.211C8.41141 10.0625 8.21004 9.97909 8.00008 9.97909C7.79012 9.97909 7.58875 10.0625 7.44029 10.211C7.29182 10.3594 7.20841 10.5608 7.20841 10.7708C7.20841 10.9807 7.29182 11.1821 7.44029 11.3305C7.58875 11.479 7.79012 11.5624 8.00008 11.5624C8.21004 11.5624 8.41141 11.479 8.55987 11.3305C8.70834 11.1821 8.79175 10.9807 8.79175 10.7708ZM8.59383 6.21867C8.59383 5.88963 8.32912 5.62492 8.00008 5.62492C7.67104 5.62492 7.40633 5.88963 7.40633 6.21867V8.59367C7.40633 8.92271 7.67104 9.18742 8.00008 9.18742C8.32912 9.18742 8.59383 8.92271 8.59383 8.59367V6.21867Z"
        fill="#D31510"
      />
    </g>
  </svg>
)
