import React from 'react'

import { List, Popconfirm, Typography, Button } from 'antd'
import gql from 'graphql-tag'
import PT from 'prop-types'

import { removeAdminsFromList } from '../utils'

const TeamInfo = props => {
  const { admins, onDelete, refetchTeams, team } = props
  const { id, name, members, updatedAt } = team

  const membersWithoutAdmins = removeAdminsFromList(members, admins)

  const onDeleteTeam = async () => {
    await onDelete(id)
    refetchTeams()
  }

  return (
    <List.Item
      className="team-info-panel-header"
      actions={[
        <>
          <Popconfirm
            title={
              <>
                <div>Are you sure you want to delete this team?</div>
                {members && members.length > 0 && (
                  <Typography.Text type="danger">
                    Please reassign or remove users from this team before deleting it.
                  </Typography.Text>
                )}
              </>
            }
            onConfirm={onDeleteTeam}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
            //okButtonProps={{ disabled: members && members.length > 0 }}
          >
            <Button type="danger" ghost size="small" style={{ marginLeft: 10 }} icon="delete">
              Delete
            </Button>
          </Popconfirm>
        </>,
      ]}
    >
      <List.Item.Meta title={name} description={`Last updated: ${updatedAt}`} />
      <div>{`${membersWithoutAdmins.length} ${
        membersWithoutAdmins.length === 1 ? 'user' : 'users'
      }`}</div>
    </List.Item>
  )
}

TeamInfo.fragments = gql`
  fragment universityClientTeamFields on UniversityClientTeam {
    id
    name
    updatedAt(format: "lll")
    members {
      id
      email
      teams {
        id
      }
    }
    documentPermissions {
      id
      document {
        id
      }
      permissions
    }
    reportPermissions {
      id
      report {
        id
      }
      permissions
    }
  }
`

TeamInfo.propTypes = {
  admins: PT.array.isRequired,
  allTeams: PT.array.isRequired,
  documents: PT.array.isRequired,
  onDelete: PT.func.isRequired,
  onEditTeamSubmit: PT.func.isRequired,
  onReassignTeamSubmit: PT.func.isRequired,
  refetchTeams: PT.func.isRequired,
  reports: PT.array.isRequired,
  tags: PT.object.isRequired,
  team: PT.object.isRequired,
}

export default TeamInfo
