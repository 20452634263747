import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import CountUp from 'react-countup'

import UniversityLogo from '../../Dashboard/components/UniversityLogo'

import {
  Top,
  Section1,
  Col1,
  Section2,
  Col2,
  SchoolName,
  PopCountSpan,
  Restrain,
  LogoTitle,
  PopCount,
} from './Styled'
import { PageTitle, PopCountText, ProfilesText, Subtitle } from './Text'

export const Header = ({ formState, data }) => {
  const totalCount = formState.university.grandTotal ?? 0
  const popCount = data?.collegeToCareerInsights?.pop_count ?? undefined

  const [knownPop, setKnownPop] = useState(0)

  useEffect(() => {
    if (popCount !== undefined && popCount !== knownPop) {
      setTimeout(() => {
        setKnownPop(popCount)
      }, 1500)
    }
  }, [popCount, knownPop])

  return (
    <Top>
      <Restrain>
        <Section1>
          <SchoolName>
            {formState.demoDimNames?.length
              ? formState.demoDimNames[0]?.toUpperCase()
              : formState.profile.clientName?.toUpperCase()}
          </SchoolName>
        </Section1>
        <Section2>
          <LogoTitle>
            <Col1>
              <UniversityLogo
                url={formState.university.finalUrl}
                additionalStyles={{ position: 'relative' }}
                margin={80}
              >
                <></>
              </UniversityLogo>
            </Col1>
            <Col2>
              <PageTitle>College to Career Insights</PageTitle>
              <Subtitle>Generate aggregate reports on alumni outcomes</Subtitle>
            </Col2>
          </LogoTitle>
          <PopCount>
            <PopCountText>
              <>
                <PopCountSpan>
                  <CountUp start={knownPop} end={popCount ?? 0} />
                </PopCountSpan>
                {` of `}
                <PopCountSpan>{totalCount}</PopCountSpan>
              </>
            </PopCountText>
            <ProfilesText>PROFILES</ProfilesText>
          </PopCount>
        </Section2>
      </Restrain>
    </Top>
  )
}

Header.propTypes = {
  formState: PropTypes.shape({
    demoDimNames: PropTypes.arrayOf(PropTypes.string),
    profile: PropTypes.shape({
      clientName: PropTypes.string,
    }),
  }).isRequired,
}
