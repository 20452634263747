import styled from 'styled-components'

export const CTCPage = styled.div`
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1;
`

export const ErrorBox = styled.div`
  background-color: #ffebe7;
  color: #222222;
  padding: 4px 8px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  margin-top: 4px;
  border-radius: 4px;
`

export const Top = styled.div`
  width: 100%;
  background-color: white;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid #e6e6e6;
  padding: 40px;

  @media (min-width: 1200px) {
    padding: 40px 80px;
  }
`

export const Restrain = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`

export const SchoolName = styled.div`
  color: #5099de;
  font-weight: bold;
  font-size: 12px;
`

export const Section1 = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
`

export const Section2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media (min-width: 600px) {
    flex-direction: row;
    align-items: flex-start;
  }
`

export const LogoTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  flex-grow: 1;
`

export const PopCount = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  @media (min-width: 600px) {
    margin-bottom: 8px;
    align-items: flex-end;
  }
`

export const Col1 = styled.div`
  margin-bottom: 8px;
  .logo-circle: {
    position: relative !important;
  }
`

export const Col2 = styled.div`
  margin-bottom: 8px;
`

export const MainRestrain = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  @media (min-width: 600px) {
    padding: 0px 24px;
  }

  @media (min-width: 900px) {
    padding: 0px 40px;
  }

  @media (min-width: 1280px) {
    padding: 0px 0px;
  }
`

export const MainContent = styled.div`
  padding: 40px 24px;
  background-color: white;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);

  @media (min-width: 600px) {
    padding: 40px 40px;
  }

  @media (min-width: 900px) {
    padding: 40px 80px;
  }
`

export const TitleBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

export const ToggleButtonArea = styled.div`
  flex: 0;
`

export const ToggleButton = styled.button`
  border: none;
  background-color: transparent;
`

export const Form = styled.form``

export const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 1200px) {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }
`

export const FormPair = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 600px) {
    display: flex;
    flex-direction: row !important;
    gap: 16px;
  }

  @media (min-width: 1200px) {
    flex 1;
  }
`

export const PairElement = styled.div`
  flex: 1;
  margin-top: 0px;
`

export const FormLabel = styled.label`
  font-weight: 700;
  color: #4c4c4c;
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 12px;
  letter-spacing: 0.32px;

  @media (min-width: 1200px) {
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
`

export const FormSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const FormSelect = styled.select`
  font-size: 14px;
  color: #464646;
`

export const FormRule = styled.hr`
  border: solid 0px #e6e6e6;
  border-bottom-width: 1px;
  margin: 32px 0px;
`

export const MobileSubsectionLabel = styled.label`
  margin-top: 24px;
  font-size: 16px;
  color: #222222;
  font-weight: 400;
`

export const FormSubmitRow = styled.div`
  margin-top: 24px;
`

export const ModalText = styled.p``

export const Results = styled.div`
  background-color: white;
  padding: 24px 0px 64px 0px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
  margin-bottom: 64px;
  margin-top: 40px;
`

export const BiggerScreen = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #f0faff;
  padding: 16px;
  gap: 8px;
  color: #464646;
  font-size: 14px;
  font-weight: 400;

  @media (min-width: 600px) {
    display: none;
  }
`

export const BigScreenText = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #464646;
`

export const IconColumn = styled.div`
  flex: 0;
`

export const MainColumn = styled.div`
  flex: 1;
`

export const TopRow = styled.div``

export const TitleDownloadRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const CSVButton = styled.a`
  display: none;

  border-radius: 4px;
  border: solid 1px #b1b1b1;
  padding: 4px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  &:hover {
    text-decoration: none;
    color: #4c4c4c;
  }

  & > *:hover {
    text-decoration: none;
    color: #4c4c4c;
  }

  @media (min-width: 600px) {
    display: block;
  }
`

export const Data = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  border-bottom: solid 1px #b1b1b1;

  @media (min-width: 1200px) {
    display: flex;
    flex-direction: row !important;
    justify-content: stretch;
    border-bottom: solid 1px #e6e6e6;
  }

  &:first-child {
    .titleRow {
      display: flex !important;
    }
  }
`

export const ResultsContent = styled.div`
  padding: 0px 24px;

  @media (min-width: 600px) {
    padding: 0px 40px;
  }

  @media (min-width: 900px) {
    padding: 0px 80px;
  }
`

export const Pagination = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 16px;
`

export const PaginationButton = styled.button`
  padding: 6px 6px;
  border-radius: 4px;
  margin: 0px 4px;
  border: solid 1px #d9d9d9;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #595959;
  font-weight: 400;
  min-width: 30px;
  min-height: 20px;
  background-color: transparent;

  &.selected {
    color: #5099de;
    border-color: #5099de;
  }
`

export const DataColumn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
  margin-bottom: 16px;

  @media (min-width: 1200px) {
    display: table-column !important;
    flex: 1;
    gap: 24px;
    width: 100%;
    position: relative;
    margin-bottom: 0px;
    border-bottom: none !important;
  }
`

export const TitleRow = styled.div`
  flex: 1;
  font-weight: 700;
  font-size: 14px;

  @media (min-width: 1200px) {
    display: none;
    height: 72px !important;
    flex-direction: row;
    font-weight: 400px;

    overflow: hidden;
    background-color: #fafafa;
    border-bottom: #e6e6e6 1px solid;
    justify-content: center;
    align-items: center;
    padding: 16px;
    margin-bottom: 8px;
  }
`

export const ResultsDataContainer = styled.div``

export const DataRow = styled.div`
  flex: 1;
  min-width: 144px;

  @media (min-width: 1200px) {
    display: table-row !important;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }
`

export const TitleDataRow = styled.div`
  flex: 1;
  min-width: 144px;

  @media (min-width: 1200px) {
    width: 100% !important;
    padding: 16px 0px;
  }
`

export const VarList = styled.ol`
  list-style-position: outside;
  margin-left: -16px;
  @media (min-width: 1200px) {
    margin-left: 8px;
  }
`

export const VarListItem = styled.li``

export const PopCountSpan = styled.span`
  font-weight: 700;
  color: #565656;
`
