import React from 'react'

import { BUILDING_ICON_URL } from '../../utils/applicationConstants'

const getOldCompanyId = id => (id && id.toString ? id.toString().replace(/\D/g, '') : '')

const CompanyLogo = props => {
  const { id, size } = props
  const addDefaultSrc = e => {
    if (e.target.src !== BUILDING_ICON_URL) {
      e.target.src = BUILDING_ICON_URL
    }
  }

  const tempCompanyId = getOldCompanyId(id)

  return (
    <div className="company-logo-container" style={{ float: 'left' }}>
      <img
        src={`${process.env.REACT_APP_LOGOS_URL}${tempCompanyId}.jpg`}
        alt="company logo"
        className="company-logo"
        style={{ height: size, width: size }}
        onError={addDefaultSrc}
      />
    </div>
  )
}

export default CompanyLogo
