import React from 'react'

import { Select } from 'antd'
import get from 'lodash/get'

import fullstory from '../../../utils/constants/fullstory'

const InputWithArrayValue = React.forwardRef((props, ref) => {
  const setFields = get(props, 'setFields')
  const handleChange = values =>
    setFields({
      email: {
        value: values,
      },
    })

  return (
    <Select
      ref={ref}
      {...props}
      autoFocus={props.autoFocus}
      mode="tags"
      dropdownStyle={{ display: 'none' }}
      onChange={handleChange}
      className={fullstory.classNames.textInput}
    />
  )
})

export default InputWithArrayValue
