import { useEffect, useState } from 'react'

import useUniversitiesForClient from '../../../../hooks/useUniversitiesForClient'
import { ctcSinglePlural } from '../../../../utils/constants/ctcStrings'
import { ctcQuery } from '../gqlQueries'

export function useCTCForm({ profile, demoDimNames }) {
  const university = useUniversitiesForClient({
    clientName: profile.clientName,
    multiSchoolDims: profile.demoDimNames,
    taxonomy: profile.taxonomy,
    topLevelFilters: {},
    url: profile.url,
  })

  const [input, setInput] = useState({
    degree_level: undefined,
    grad_year_max: undefined,
    grad_year_min: undefined,
    highest_degree_level_anywhere: undefined,
    include_university_employees: undefined,
    level: undefined,
    n_results: undefined,
    statistical_significance: undefined,
    universities: [],
  })

  const fromYearMax = new Date().getFullYear()

  const [formData, setFormData] = useState({
    aggregation_variable: 'top_employers',
    degree_level: 'bachelors',
    grad_year_max: fromYearMax,
    grad_year_min: fromYearMax - 10,
    highest_degree_level_anywhere: null,
    include_university_employees: true,
    level: 'universities',
    max_results: 10,
    statistical_significance: true,
  })

  const [formErrors, setFormErrors] = useState({})

  useEffect(() => {
    const errors = {}
    if (formData.grad_year_min > formData.grad_year_max) {
      errors.grad_year_min = 'Invalid date range'
      errors.grad_year_max = 'Invalid date range'
    }
    const degreeLevels = ['associate', 'bachelors', 'masters', 'doctorate']
    if (
      formData.highest_degree_level_anywhere !== null &&
      formData.degree_level !== null &&
      degreeLevels.indexOf(formData.degree_level) >
        degreeLevels.indexOf(formData.highest_degree_level_anywhere)
    ) {
      errors.degree_level = 'Invalid selections'
      errors.highest_degree_level_anywhere = 'Invalid selections'
    }
    setFormErrors(errors)
  }, [formData])

  const [aggregationVariable, setAggregationVariable] = useState('top_employers')
  const [numResults, setNumResults] = useState(10)
  const [showSampleAlert, setShowSampleAlert] = useState(false)
  const [paginationPage, setPaginationPage] = useState(0)
  const [hideForm, setHideForm] = useState(false)

  const variables = [
    'top_employers',
    'top_cities',
    'top_states',
    'top_industries',
    'top_job_titles',
  ]

  const consts = {
    aggregationVariableOptions: variables,
    degreeLevels: ['associate', 'bachelors', 'masters', 'doctorate'],
    fromYearMax,
    fromYearMin: 1950,
    levelOptions: ['universities', 'colleges', 'majors'],
    toYearMax: new Date().getFullYear(),
    toYearMin: 1970,
    variableDisplayNames: ctcSinglePlural,
  }

  return {
    aggregationVariable,
    consts,
    ctcQuery,
    demoDimNames,
    formData,
    formErrors,
    hideForm,
    input,
    numResults,
    paginationPage,
    profile,
    setAggregationVariable,
    setFormData,
    setFormErrors,
    setHideForm,
    setInput,
    setNumResults,
    setPaginationPage,
    setShowSampleAlert,
    showSampleAlert,
    university,
  }
}
