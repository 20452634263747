import React from 'react'

import { Button, Checkbox, Icon, Modal, Select } from 'antd'

import { useScreenWidth } from '../hooks/useScreenWidth'

import {
  MainContent,
  TitleBar,
  ToggleButtonArea,
  ToggleButton,
  FormRow,
  FormPair,
  FormLabel,
  FormSubmitRow,
  PairElement,
  ErrorBox,
  ModalText,
  FormRule,
} from './Styled'
import { FormTitle, FormSubtitle } from './Text'
import { TriangleExclamation } from './triangle-exclamation'
export const FiltersForm = ({ formState }) => {
  const screenWidth = useScreenWidth()

  const defaults = {
    aggregationVariableDefault: 'top_employers',
    aggregationVariableOptions: formState.consts.aggregationVariableOptions,
    degreeLevelDefault: 'bachelors',
    degreeLevels: ['associate', 'bachelors', 'masters', 'doctorate'],
    fromYearDefault: formState.consts.fromYearMax - 10,
    fromYearMax: formState.consts.fromYearMax,
    fromYearMin: formState.consts.fromYearMin,
    fromYearOptions: Array.from(
      { length: formState.consts.fromYearMax - formState.consts.fromYearMin + 1 },
      (_, i) => i + formState.consts.fromYearMin,
    ),
    highestDegreeAnywhereDefault: null,
    highestDegreeAnywhereOptions: ['associate', 'bachelors', 'masters', 'doctorate'],
    includeUniversityEmployeesDefault: true,
    levelDefault: 'universities',
    levelOptions: ['universities', 'colleges', 'majors'],
    maxResultsDefault: 10,
    maxResultsOptions: Array.from({ length: 6 }, (_, i) => 5 + i * 5),
    nResultsDefault: 5,
    statisticalSignificanceDefault: true,
    toYearDefault: formState.consts.toYearMax,
    toYearMax: formState.consts.toYearMax,
    toYearMin: formState.consts.toYearMin,
    toYearOptions: Array.from(
      { length: formState.consts.toYearMax - formState.consts.toYearMin + 1 },
      (_, i) => i + formState.consts.toYearMin,
    ),
    variableDisplayNames: formState.consts.variableDisplayNames,
  }

  const universities = formState.university.universities

  return (
    <MainContent>
      <TitleBar>
        <FormTitle>Filters</FormTitle>
        <ToggleButtonArea>
          <ToggleButton>
            {formState.hideForm ? (
              <Icon type="down" onClick={() => formState.setHideForm(false)} />
            ) : (
              <Icon type="up" onClick={() => formState.setHideForm(true)} />
            )}
          </ToggleButton>
        </ToggleButtonArea>
      </TitleBar>
      {!formState.hideForm && (
        <form
          onSubmit={e => {
            e.preventDefault()
            formState.setInput({
              degree_level: formState.formData.degree_level,
              grad_year_max: formState.formData.grad_year_max,
              grad_year_min: formState.formData.grad_year_min,
              highest_degree_level_anywhere: formState.formData.highest_degree_level_anywhere,
              include_university_employees: formState.formData.include_university_employees,
              level: formState.formData.level,
              n_results: formState.formData.max_results,
              statistical_significance: formState.formData.statistical_significance,
              taxonomy: formState.university.finalTaxonomy,
              universities,
            })
            formState.setPaginationPage(0)
            formState.setAggregationVariable(formState.formData.aggregation_variable)
            formState.setNumResults(formState.formData.max_results)
            return false
          }}
          method="post"
        >
          <FormSubtitle>Define Alumni Population</FormSubtitle>
          <FormRow>
            <FormPair>
              <PairElement>
                <FormLabel>Graduate Year From</FormLabel>
                <Select
                  defaultValue={defaults.fromYearDefault}
                  value={formState.formData.grad_year_min}
                  onChange={e =>
                    formState.setFormData({
                      ...formState.formData,
                      grad_year_min: parseInt(`${e}`),
                    })
                  }
                >
                  {defaults.fromYearOptions.map(year => (
                    <Select.Option key={year} value={year}>
                      {year}
                    </Select.Option>
                  ))}
                </Select>
                {formState.formErrors.grad_year_min && (
                  <ErrorBox>
                    <TriangleExclamation style={{ marginRight: 4 }} />
                    {formState.formErrors.grad_year_min}
                  </ErrorBox>
                )}
              </PairElement>
              <PairElement>
                <FormLabel>Graduate Year To</FormLabel>
                <Select
                  defaultValue={defaults.toYearDefault}
                  value={formState.formData.grad_year_max}
                  onChange={e =>
                    formState.setFormData({
                      ...formState.formData,
                      grad_year_max: parseInt(`${e}`),
                    })
                  }
                >
                  {defaults.toYearOptions.map(year => (
                    <Select.Option key={year} value={year}>
                      {year}
                    </Select.Option>
                  ))}
                </Select>
                {formState.formErrors.grad_year_max && (
                  <ErrorBox>
                    <TriangleExclamation style={{ marginRight: 4 }} />
                    {formState.formErrors.grad_year_max}
                  </ErrorBox>
                )}
              </PairElement>
            </FormPair>
            <FormPair>
              <PairElement>
                <FormLabel>Degree Level</FormLabel>
                <Select
                  defaultValue={defaults.degreeLevelDefault}
                  value={formState.formData.degree_level}
                  onChange={e =>
                    formState.setFormData({
                      ...formState.formData,
                      degree_level: e,
                    })
                  }
                >
                  <Select.Option key="null" value={null}>
                    All Levels
                  </Select.Option>
                  {defaults.degreeLevels.map(degreeLevel => (
                    <Select.Option key={degreeLevel} value={degreeLevel}>
                      {defaults.variableDisplayNames[degreeLevel][1]}
                    </Select.Option>
                  ))}
                </Select>
                {formState.formErrors.degree_level && (
                  <ErrorBox>
                    <TriangleExclamation style={{ marginRight: 4 }} />
                    {formState.formErrors.degree_level}
                  </ErrorBox>
                )}
              </PairElement>
              <PairElement>
                <FormLabel>Highest degree level anywhere</FormLabel>
                <Select
                  defaultValue={defaults.highestDegreeAnywhereDefault}
                  value={formState.formData.highest_degree_level_anywhere}
                  onChange={e =>
                    formState.setFormData({
                      ...formState.formData,
                      highest_degree_level_anywhere: e,
                    })
                  }
                >
                  <Select.Option key="null" value={null}>
                    All Levels
                  </Select.Option>
                  {defaults.highestDegreeAnywhereOptions.map(degreeLevel => (
                    <Select.Option key={degreeLevel} value={degreeLevel}>
                      {defaults.variableDisplayNames[degreeLevel][1]}
                    </Select.Option>
                  ))}
                </Select>
                {formState.formErrors.highest_degree_level_anywhere && (
                  <ErrorBox>
                    <TriangleExclamation style={{ marginRight: 4 }} />
                    {formState.formErrors.highest_degree_level_anywhere}
                  </ErrorBox>
                )}
              </PairElement>
            </FormPair>
          </FormRow>
          <FormRow>
            <FormPair>
              <PairElement>
                <Checkbox
                  checked={formState.formData.include_university_employees}
                  onChange={e =>
                    formState.setFormData({
                      ...formState.formData,
                      include_university_employees: e.target.checked,
                    })
                  }
                >
                  Include University Employees
                </Checkbox>
              </PairElement>
            </FormPair>
          </FormRow>
          <FormRule />

          <FormSubtitle>Define Aggregation</FormSubtitle>
          <FormRow>
            <FormPair>
              <PairElement>
                <FormLabel>Aggregation Level</FormLabel>
                <Select
                  defaultValue={defaults.levelDefault}
                  value={formState.formData.level}
                  onChange={e =>
                    formState.setFormData({
                      ...formState.formData,
                      level: e,
                    })
                  }
                >
                  {defaults.levelOptions.map(level => (
                    <Select.Option key={level} value={level}>
                      {defaults.variableDisplayNames[level][1]}
                    </Select.Option>
                  ))}
                </Select>
              </PairElement>
              <PairElement>
                <FormLabel>Aggregation Variable</FormLabel>
                <Select
                  defaultValue={defaults.aggregationVariableDefault}
                  value={formState.formData.aggregation_variable}
                  onChange={e =>
                    formState.setFormData({
                      ...formState.formData,
                      aggregation_variable: e,
                    })
                  }
                >
                  {defaults.aggregationVariableOptions.map(variable => (
                    <Select.Option key={variable} value={variable}>
                      {defaults.variableDisplayNames[variable][1]}
                    </Select.Option>
                  ))}
                </Select>
              </PairElement>
            </FormPair>
            <FormPair>
              <PairElement>
                <FormLabel>Top # of variable results</FormLabel>
                <Select
                  defaultValue={defaults.maxResultsDefault}
                  value={formState.formData.max_results}
                  onChange={e =>
                    formState.setFormData({
                      ...formState.formData,
                      max_results: parseInt(`${e}`),
                    })
                  }
                >
                  {defaults.maxResultsOptions
                    .filter(i => i <= (screenWidth < 600 ? 10 : Infinity))
                    .map(i => (
                      <Select.Option key={i} value={i}>
                        {' '}
                        {i}
                      </Select.Option>
                    ))}
                </Select>
              </PairElement>
            </FormPair>
          </FormRow>
          <FormRow>
            <FormPair>
              <Checkbox
                checked={formState.formData.statistical_significance}
                onChange={e => {
                  formState.setShowSampleAlert(true)
                  formState.setFormData({
                    ...formState.formData,
                    statistical_significance: e.target.checked,
                  })
                }}
              >
                Minimum Sample Size
              </Checkbox>
              <Modal
                onCancel={() => {
                  formState.setFormData({
                    ...formState.formData,
                    statistical_significance: true,
                  })
                  formState.setShowSampleAlert(false)
                }}
                onOk={() => {
                  formState.setFormData({
                    ...formState.formData,
                    statistical_significance: false,
                  })
                  formState.setShowSampleAlert(false)
                }}
                title={<b>Override minimum sample size?</b>}
                footer={[
                  <Button
                    onClick={() => {
                      formState.setFormData({
                        ...formState.formData,
                        statistical_significance: true,
                      })
                      formState.setShowSampleAlert(false)
                    }}
                    key="cancel"
                  >
                    Cancel
                  </Button>,
                  <Button
                    type="danger"
                    style={{ backgroundColor: '#D31510' }}
                    onClick={() => {
                      formState.setFormData({
                        ...formState.formData,
                        statistical_significance: false,
                      })
                      formState.setShowSampleAlert(false)
                    }}
                    key="override"
                  >
                    Override
                  </Button>,
                ]}
                visible={formState.showSampleAlert && !formState.formData.statistical_significance}
              >
                <ModalText>
                  Making this selection overrides the minimum sample size of 30.
                </ModalText>
                <ModalText>
                  Small samples are less representative of the broader population and are more
                  likely to show random variations or outliers that don&apos;t reflect the true
                  trends or patterns. For more reliable insights, a larger sample size gives a
                  clearer and more accurate picture.
                </ModalText>
              </Modal>
            </FormPair>
          </FormRow>
          <FormSubmitRow>
            <Button type="primary" htmlType="submit">
              Apply Filters
            </Button>
          </FormSubmitRow>
        </form>
      )}
    </MainContent>
  )
}
