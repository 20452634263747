import React from 'react'
import AuthenticatedLayout from '../components/AuthenticatedLayout'
import { useUniversityClientUserProfile } from './hooks/useUniversityClientUserProfile'
import { Query } from '@apollo/client/react/components/Query'
import { FiltersForm } from './components/FiltersForm'
import { getDemoDimNames } from '../../../utils/superUserDataInteraction'
import { useCTCForm } from './hooks/useCTCForm'
import { CTCOutput } from './components/CTCOutput'
import { Header } from './components/Header'
import { CTCPage, ResultsContent, ErrorBox, MainRestrain } from './components/Styled'
import { LoadingText } from './components/Text'

const CollegeToCareerInsights = () => {
  const profile = useUniversityClientUserProfile()

  const demoDimNames = getDemoDimNames()
  const formState = useCTCForm({ profile, demoDimNames })

  const hasInput = !!formState.input.universities?.length

  return (
    <AuthenticatedLayout>
      <Query
        query={formState.ctcQuery}
        variables={{
          input: formState.input,
        }}
        skip={!hasInput}
      >
        {({ data, error, loading }) => {
          return (
            <CTCPage>
              <Header formState={formState} data={data} />
              <MainRestrain>
                <FiltersForm formState={formState} />
                {hasInput && !loading && !error && (
                  <CTCOutput formState={formState} data={data} hasInput={hasInput} />
                )}
                {hasInput && loading && (
                  <ResultsContent
                    style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}
                  >
                    <LoadingText>Loading..</LoadingText>
                  </ResultsContent>
                )}
                {hasInput && error && (
                  <ResultsContent
                    style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}
                  >
                    <ErrorBox>Error: {error.message}</ErrorBox>
                  </ResultsContent>
                )}
              </MainRestrain>
            </CTCPage>
          )
        }}
      </Query>
    </AuthenticatedLayout>
  )
}

export default CollegeToCareerInsights
