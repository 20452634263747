import gql from 'graphql-tag'

export const universityClientUserProfileQuery = gql`
  query UniversityClientUserProfile {
    universityClientUserProfile {
      clientName
      gradExplorerPermission
      haveIncludeUniversityEmployees
      id
      isAdmin
      multiSchoolDims
      taxonomy
      url
      reports {
        name
        userReportPermissions
      }
    }
  }
`

export const ctcQuery = gql`
  query CollegeToCareerInsightsQuery($input: CTC_AggregationInput!) {
    collegeToCareerInsights(input: $input) {
      level
      pop_count
      pop_avg_salary
      total_count
      level_results {
        level_key
        level_count
        level_avg_salary
        variable_results {
          variable
          count
          avg_salary
          results
        }
      }
    }
  }
`
