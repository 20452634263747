import { useQuery } from '@apollo/client'
import get from 'lodash/get'

import errorService from '../../../../utils/analytics/error'
import { pickWithDefaults } from '../../../../utils/data/utils'
import { universityClientUserProfileQuery } from '../gqlQueries'

export const useUniversityClientUserProfile = () => {
  const { data, error } = useQuery(universityClientUserProfileQuery, {
    fetchPolicy: 'network-only',
    onError: error => {
      errorService.report(error, 'universityClientUserProfileQuery')
    },
  })

  const universityClientUserProfile = get(data, 'universityClientUserProfile', {})

  const {
    clientName,
    gradExplorerPermission,
    multiSchoolDims,
    taxonomy,
    url,
    reports,
  } = pickWithDefaults(universityClientUserProfile, {
    clientName: '',
    gradExplorerPermission: 'DOWNLOAD_LIMITED',
    multiSchoolDims: null,
    reports: [],
    taxonomy: 'general',
    url: null,
  })

  const canDownload = reports.some(
    report =>
      report.name === 'College to Career Insights' &&
      report.userReportPermissions.includes('DOWNLOAD'),
  )

  return {
    canDownload,
    clientName,
    gradExplorerPermission,
    multiSchoolDims,
    reports,
    taxonomy,
    universityClientUserProfileError: error,
    url,
  }
}
