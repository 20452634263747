import React from 'react'

import { Route, Switch } from 'react-router-dom'

import withAsyncLoading from '../../components/hocs/withAsyncLoading'
import { useProtectAuthenticatedRoutes } from '../../utils/authentication'
import { AUTHENTICATED_ROUTES } from '../../utils/routing/routes'
import { loadWithDelay } from '../../utils/routing/routes/load'

import CollegeToCareerInsights from './CollegeToCareerInsights'
import GraduateOutcomes from './GraduateOutcomes'
import ManageTeam from './ManageTeam'
import MyAccount from './MyAccount'

const Dashboard = React.lazy(loadWithDelay(import('./Dashboard'), 500))
const DataFiles = React.lazy(loadWithDelay(import('./DataFiles'), 500))
const Demo = React.lazy(loadWithDelay(import('./Demo'), 500))

const GraduateExplorer = React.lazy(loadWithDelay(import('./GraduateExplorer'), 500))
const Help = React.lazy(loadWithDelay(import('./Help'), 500))
const TableauReportShow = React.lazy(loadWithDelay(import('./TableauReportShow'), 500))

const Authenticated = props => {
  useProtectAuthenticatedRoutes(props.history)
  return (
    <Switch>
      <Route path={AUTHENTICATED_ROUTES.DATA_FILES} component={withAsyncLoading(DataFiles)} />
      <Route path={AUTHENTICATED_ROUTES.DEMO} component={withAsyncLoading(Demo)} />
      <Route path={AUTHENTICATED_ROUTES.HELP} component={withAsyncLoading(Help)} />
      <Route
        path={AUTHENTICATED_ROUTES.GRADUATE_OUTCOMES_EMPLOYERS}
        component={GraduateOutcomes}
        exact
      />
      <Route
        path={AUTHENTICATED_ROUTES.COLLEGE_TO_CAREER_INSIGHTS}
        component={withAsyncLoading(CollegeToCareerInsights)}
        exact
      />
      <Route
        path={AUTHENTICATED_ROUTES.GRADUATE_OUTCOMES_SKILLS}
        component={GraduateOutcomes}
        exact
      />
      <Route exact path={AUTHENTICATED_ROUTES.GRADUATE_OUTCOMES} component={GraduateOutcomes} />
      <Route
        path={AUTHENTICATED_ROUTES.GRADUATE_EXPLORER}
        component={withAsyncLoading(GraduateExplorer)}
      />
      <Route path={AUTHENTICATED_ROUTES.MANAGE_TEAM} component={withAsyncLoading(ManageTeam)} />
      <Route path={AUTHENTICATED_ROUTES.MY_ACCOUNT} component={withAsyncLoading(MyAccount)} />
      <Route
        path={AUTHENTICATED_ROUTES.TABLEAU_REPORT}
        component={withAsyncLoading(TableauReportShow)}
      />
      <Route component={withAsyncLoading(Dashboard)} />
    </Switch>
  )
}

export default Authenticated
