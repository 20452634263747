import React from 'react'

import { Typography } from 'antd'

export const PageTitle = ({ children }) => {
  return (
    <Typography.Title
      level={2}
      style={{
        color: '#4C4C4C',
        fontSize: '28px',
        fontWeight: 400,
        marginBottom: '8px',
      }}
    >
      {children}
    </Typography.Title>
  )
}

export const Subtitle = ({ children }) => {
  return (
    <Typography.Title level={3} style={{ color: '#4C4C4C', fontSize: '16px', fontWeight: 400 }}>
      {children}
    </Typography.Title>
  )
}

export const FormTitle = ({ children }) => {
  return (
    <Typography.Title level={3} style={{ fontSize: '24px' }}>
      {children}
    </Typography.Title>
  )
}

export const FormSubtitle = ({ children }) => {
  return (
    <Typography.Title level={4} style={{ fontSize: '20px' }}>
      {children}
    </Typography.Title>
  )
}

export const ResultsTitle = ({ children }) => {
  return (
    <Typography.Title level={2} style={{ fontSize: '24px', margin: '24px 0px' }}>
      {children}
    </Typography.Title>
  )
}

export const BigScreenTitle = ({ children }) => {
  return (
    <Typography.Title level={2} style={{ color: '#222222', fontSize: '14px', fontWeight: 700 }}>
      {children}
    </Typography.Title>
  )
}

export const LoadingText = ({ children }) => {
  return (
    <Typography.Text style={{ color: '#222222', fontSize: '20px', textAlign: 'center' }}>
      {children}
    </Typography.Text>
  )
}

export const PopCountText = ({ children }) => {
  return (
    <Typography.Text style={{ fontSize: '16px', lineHeight: '20px' }}>{children}</Typography.Text>
  )
}

export const TotalCountText = ({ totalCount }) => {
  return (
    <Typography.Text style={{ fontSize: '16px', lineHeight: '20px' }}>{totalCount}</Typography.Text>
  )
}

export const ProfilesText = ({ children }) => {
  return (
    <Typography.Text
      style={{ color: '#FF9551', fontSize: '14px', fontWeight: 700, lineHeight: '20px' }}
    >
      {children}
    </Typography.Text>
  )
}
