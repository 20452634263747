import React from "react"

const toCSVSafeString = str => {
    if (str.includes('"') || str.includes(',')) {
        return `"${str.replace(/"/g, '""')}"`
    }
    return str
}

export const toCSVString = ({
    variableDisplayNames,
    input,
    aggregationVariable,
    numResults,
    tableData
}) => {
    const csvColumns = [
        {
            title: variableDisplayNames[input.level][0],
            dataIndex: 'name',
            key: 'name',
            csvRender: name => toCSVSafeString(name),
        },
        {
            title: `Top ${numResults} ${variableDisplayNames[aggregationVariable][1]}`,
            dataIndex: 'results',
            key: 'results',
            render: results =>
                results.map((result, index) => (
                    <div key={index}>
                        {index + 1}. {result}
                    </div>
                )),
            csvRender: results => toCSVSafeString(results.join(',')),
        },
        {
            title: `Grads in Top ${variableDisplayNames[aggregationVariable][1]}`,
            dataIndex: 'count',
            key: 'count',
            csvRender: count => toCSVSafeString(count.toLocaleString()),
        },
        {
            title: `Salary in Top ${variableDisplayNames[aggregationVariable][1]}`,
            dataIndex: 'avgSalary',
            key: 'avgSalary',
            render: avgSalary => `$${Math.ceil(avgSalary).toLocaleString()}`,
            csvRender: avgSalary =>
                toCSVSafeString(`$${Math.ceil(avgSalary).toLocaleString()}`),
        },
        {
            title: `Grads in Population`,
            dataIndex: 'levelCount',
            key: 'levelCount',
            csvRender: levelCount => toCSVSafeString(levelCount.toLocaleString()),
        },
        {
            title: `Salary in Population`,
            dataIndex: 'levelAvgSalary',
            key: 'levelAvgSalary',
            render: avgSalary => `$${Math.ceil(avgSalary).toLocaleString()}`,
            csvRender: avgSalary =>
                toCSVSafeString(`$${Math.ceil(avgSalary).toLocaleString()}`),
        },
    ]

    const csvString =
        csvColumns.map(column => column.title).join(',') +
        '\n' +
        tableData
            .map(row => csvColumns.map(column => column.csvRender(row[column.dataIndex])).join(','))
            .join('\n')

    return csvString;
}