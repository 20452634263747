export const ctcSinglePlural = {
  associate: ['Associate', 'Associates'],
  bachelors: ['Bachelors', 'Bachelors'],
  colleges: ['College', 'Colleges'],
  doctorate: ['Doctorate', 'Doctorate'],
  majors: ['Major', 'Majors'],
  masters: ['Masters', 'Masters'],
  programs: ['Program', 'Programs'],
  top_cities: ['City', 'Cities'],
  top_employers: ['Employer', 'Employers'],
  top_industries: ['Industry', 'Industries'],
  top_job_titles: ['Job Title', 'Job Titles'],
  top_states: ['State', 'States'],
  universities: ['University', 'Universities'],
}
