import React from 'react'

import { Icon } from 'antd'

import { toCSVString } from '../helpers/toCSVString'
import { useScreenWidth } from '../hooks/useScreenWidth'

import {
  Results,
  BiggerScreen,
  CSVButton,
  TitleDownloadRow,
  IconColumn,
  MainColumn,
  Pagination,
  PaginationButton,
  Data,
  TopRow,
  DataColumn,
  DataRow,
  TitleRow,
  ResultsDataContainer,
  BigScreenText,
  VarList,
  VarListItem,
  ResultsContent,
} from './Styled'
import { BigScreenTitle, ResultsTitle } from './Text'

const getDisplayData = data => {
  if (!data) return {}
  const base = data.collegeToCareerInsights
  const displayData = {}
  displayData.popCount = base.pop_count
  displayData.popAvgSalary = base.pop_avg_salary
  let searchedPop = 0
  if (base.level_results) {
    displayData.displayLevelResults = base.level_results.map(levelResult => {
      const displayLevelResult = {}
      searchedPop += levelResult.level_count
      displayLevelResult.levelKey = levelResult.level_key
      displayLevelResult.levelCount = levelResult.level_count
      displayLevelResult.levelAvgSalary = levelResult.level_avg_salary
      levelResult.variable_results.forEach(variableResult => {
        displayLevelResult[variableResult.variable] = {
          avgSalary: variableResult.avg_salary,
          count: variableResult.count,
          results: variableResult.results,
        }
      })
      return displayLevelResult
    })
  }
  displayData.searchedPop = searchedPop
  return displayData
}

export const CTCOutput = ({ formState, data, hasInput }) => {
  const displayData = getDisplayData(data)

  const screenWidth = useScreenWidth()

  const tableData = displayData.popCount
    ? displayData.displayLevelResults.map(levelResult => {
        return {
          avgSalary: levelResult[formState.aggregationVariable].avgSalary,
          count: levelResult[formState.aggregationVariable].count,
          key: levelResult.levelKey,
          levelAvgSalary: levelResult.levelAvgSalary,
          levelCount: levelResult.levelCount,
          name: levelResult.levelKey,
          results: levelResult[formState.aggregationVariable].results,
        }
      })
    : []

  const csvString = hasInput
    ? toCSVString({
        aggregationVariable: formState.aggregationVariable,
        input: formState.input,
        numResults: formState.numResults,
        tableData,
        variableDisplayNames: formState.consts.variableDisplayNames,
      })
    : ''

  const csvBlob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' })
  const csvUrl = URL.createObjectURL(csvBlob)

  return (
    <Results className="resultsContent">
      <BiggerScreen>
        <IconColumn>
          <Icon type="info-circle" />
        </IconColumn>
        <MainColumn>
          <BigScreenTitle>Try viewing on a bigger screen</BigScreenTitle>
          <BigScreenText>
            Switch to a larger screen for more filtering capabilities and the ability to download
            CSV
          </BigScreenText>
        </MainColumn>
      </BiggerScreen>
      <ResultsContent>
        <TopRow>
          <TitleDownloadRow>
            <ResultsTitle>Aggregate Data Summary</ResultsTitle>
            {screenWidth > 600 && formState.profile.canDownload && (
              <CSVButton href={csvUrl} download="College To Careers Data.csv">
                Download CSV
                <Icon type="download" style={{ color: '#000', fontSize: '24px' }} />
              </CSVButton>
            )}
          </TitleDownloadRow>
        </TopRow>
        {tableData.length > 5 ? (
          <Pagination>
            <PaginationButton
              onClick={() => {
                if (formState.paginationPage > 0) {
                  formState.setPaginationPage(formState.paginationPage - 1)
                }
              }}
            >
              <Icon type="left" />
            </PaginationButton>
            {[...Array(Math.ceil(tableData.length / 5)).keys()].map((_, idx) => (
              <PaginationButton
                className={formState.paginationPage === idx ? 'selected' : ''}
                onClick={() => {
                  formState.setPaginationPage(idx)
                }}
                key={idx}
              >
                {idx + 1}
              </PaginationButton>
            ))}
            <PaginationButton
              onClick={() => {
                if (formState.paginationPage < Math.ceil(tableData.length / 5) - 1) {
                  formState.setPaginationPage(formState.paginationPage + 1)
                }
              }}
            >
              <Icon type="right" />
            </PaginationButton>
          </Pagination>
        ) : (
          <></>
        )}
        <ResultsDataContainer>
          {tableData
            .slice(formState.paginationPage * 5, formState.paginationPage * 5 + 5)
            .map(row => (
              <Data key={row.key}>
                <DataColumn>
                  <TitleRow className="titleRow">
                    {formState.consts.variableDisplayNames[data.collegeToCareerInsights.level][0]}
                  </TitleRow>
                  <DataRow>{row.key}</DataRow>
                </DataColumn>
                <DataColumn>
                  <TitleRow className="titleRow">
                    Top {row.results.length}{' '}
                    {formState.consts.variableDisplayNames[formState.aggregationVariable][1]}
                  </TitleRow>
                  <DataRow leftAligned>
                    <VarList>
                      {row.results.map(result => (
                        <VarListItem key={result}>{result}</VarListItem>
                      ))}
                    </VarList>
                  </DataRow>
                </DataColumn>
                <DataColumn>
                  <TitleRow className="titleRow">
                    Grads in Top{' '}
                    {formState.consts.variableDisplayNames[formState.aggregationVariable][1]}
                  </TitleRow>
                  <DataRow>{row.count?.toLocaleString()}</DataRow>
                </DataColumn>
                <DataColumn>
                  <TitleRow className="titleRow">
                    Salary in Top{' '}
                    {formState.consts.variableDisplayNames[formState.aggregationVariable][1]}
                  </TitleRow>
                  <DataRow>
                    $
                    {row.avgSalary?.toLocaleString('en-US', {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                  </DataRow>
                </DataColumn>
                <DataColumn>
                  <TitleRow className="titleRow">Grads in Population</TitleRow>
                  <DataRow>{row.levelCount?.toLocaleString()}</DataRow>
                </DataColumn>
                <DataColumn>
                  <TitleRow className="titleRow">Salary in Population</TitleRow>
                  <DataRow>
                    $
                    {row.levelAvgSalary?.toLocaleString('en-US', {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                  </DataRow>
                </DataColumn>
              </Data>
            ))}
        </ResultsDataContainer>
      </ResultsContent>
    </Results>
  )
}
