const createAuthenticatedRoute = (route = '') => `/app${route}`

const createManageTeamRoute = (route = '') => createAuthenticatedRoute(`/manage-team${route}`)

const createMyAccountRoute = (route = '') => createAuthenticatedRoute(`/my-account${route}`)

export const AUTHENTICATED_ROUTES = {
  COLLEGE_TO_CAREER_INSIGHTS: createAuthenticatedRoute('/college-to-career-insights'),
  DASHBOARD: createAuthenticatedRoute(),
  DATA_FILES: createAuthenticatedRoute('/data-files'),
  DEMO: createAuthenticatedRoute('/demo'),
  DOWNLOADS: createAuthenticatedRoute('/downloads'),
  GRADUATE_EXPLORER: createAuthenticatedRoute('/graduate-explorer'),
  GRADUATE_OUTCOMES: createAuthenticatedRoute('/graduate-outcomes'),
  GRADUATE_OUTCOMES_EMPLOYERS: createAuthenticatedRoute('/graduate-outcomes/employers'),
  GRADUATE_OUTCOMES_SKILLS: createAuthenticatedRoute('/graduate-outcomes/skills'),
  HELP: createAuthenticatedRoute('/help'),
  MANAGE_TEAM: createManageTeamRoute(),
  MY_ACCOUNT: createMyAccountRoute(),
  TABLEAU_GRADUATE_EXPLORER: createAuthenticatedRoute('/tableau-graduate-explorer'),
  TABLEAU_GRADUATE_OUTCOMES: createAuthenticatedRoute('/tableau-graduate-outcomes'),
  TABLEAU_REPORT: createAuthenticatedRoute('/tableau-report/:reportName'),
  UPDATE_PAYMENT: createAuthenticatedRoute('/update-payment'),
  USER_VIEWS: createAuthenticatedRoute('/user-views'),
}

export const MANAGE_TEAM_ROUTES = {
  MEMBERS: createManageTeamRoute('/members'),
  TEAMS: createManageTeamRoute(),
}

export const MY_ACCOUNT_ROUTES = {
  BASIC_INFO: createMyAccountRoute(),
  CHANGE_PASSWORD: createMyAccountRoute('/change-password'),
  SUBSCRIPTION: createMyAccountRoute('/subscription'),
}

export const UNAUTHENTICATED_ROUTES = {
  AUTHENTICATE: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  REGISTER: '/signup',
  RESET_PASSWORD: '/reset-password',
}
